{
    "Stylized_Stone_Floor_001": {
        "repeat": 100,
        "ambientmap": "textures/Floor/Stylized_Stone_Floor_001/Stylized_Stone_Floor_001_ambientOcclusion.jpg",
        "normalmap": "textures/Floor/Stylized_Stone_Floor_001/Stylized_Stone_Floor_001_normal.jpg",
        "colormap": "textures/Floor/Stylized_Stone_Floor_001/Stylized_Stone_Floor_001_basecolor.jpg",
        "roughnessmap": "textures/Floor/Stylized_Stone_Floor_001/Stylized_Stone_Floor_001_roughness.jpg"
    },
    "Rubber_Floor_001": {
        "repeat": 100,
        "reflective": 1.0,
        "roughnessmap": "textures/Floor/Rubber_Floor_001/Rubber_Floor_001_roughness.jpg",
        "colormap": "textures/Floor/Rubber_Floor_001/Rubber_Floor_001_basecolor.jpg",
        "ambientmap": "textures/Floor/Rubber_Floor_001/Rubber_Floor_001_ambientOcclusion.jpg",
        "normalmap": "textures/Floor/Rubber_Floor_001/Rubber_Floor_001_normal.jpg"
    },
    "Wood_Herringbone_Tiles_001": {
        "repeat": 100,
        "ambientmap": "textures/Floor/Wood_Herringbone_Tiles_001/Wood_Herringbone_Tiles_001_ambientOcclusion.jpg",
        "colormap": "textures/Floor/Wood_Herringbone_Tiles_001/Wood_Herringbone_Tiles_001_basecolor.jpg",
        "normalmap": "textures/Floor/Wood_Herringbone_Tiles_001/Wood_Herringbone_Tiles_001_normal.jpg",
        "roughnessmap": "textures/Floor/Wood_Herringbone_Tiles_001/Wood_Herringbone_Tiles_001_roughness.jpg"
    },
    "Stone_Tiles_004": {
        "repeat": 100,
        "ambientmap": "textures/Floor/Stone_Tiles_004/Stone_Tiles_004_ambientOcclusion.jpg",
        "roughnessmap": "textures/Floor/Stone_Tiles_004/Stone_Tiles_004_roughness.jpg",
        "normalmap": "textures/Floor/Stone_Tiles_004/Stone_Tiles_004_normal.jpg",
        "colormap": "textures/Floor/Stone_Tiles_004/Stone_Tiles_004_basecolor.jpg"
    },
    "Terracotta_Tiles_003": {
        "repeat": 100,
        "reflective": 1.0,
        "normalmap": "textures/Floor/Terracotta_Tiles_003/Terracota_Tiles_003_normal.jpg",
        "ambientmap": "textures/Floor/Terracotta_Tiles_003/Terracota_Tiles_003_ambientOcclusion.jpg",
        "colormap": "textures/Floor/Terracotta_Tiles_003/Terracota_Tiles_003_basecolor.jpg",
        "roughnessmap": "textures/Floor/Terracotta_Tiles_003/Terracota_Tiles_003_roughness.jpg"
    },
    "Marble_Tiles_001": {
        "repeat": 100,
        "reflective": 0.1,
        "ambientmap": "textures/Floor/Marble_Tiles_001/Marble_Tiles_001_ambientOcclusion.jpg",
        "colormap": "textures/Floor/Marble_Tiles_001/Marble_Tiles_001_basecolor.jpg",
        "roughnessmap": "textures/Floor/Marble_Tiles_001/Marble_Tiles_001_roughness.jpg",
        "normalmap": "textures/Floor/Marble_Tiles_001/Marble_Tiles_001_normal.jpg"
    },
    "Terrazzo_Tiles_001": {
        "repeat": 100,
        "roughnessmap": "textures/Floor/Terrazzo_Tiles_001/Terrazzo_Tiles_001_roughness.jpg",
        "ambientmap": "textures/Floor/Terrazzo_Tiles_001/Terrazzo_Tiles_001_ambientOcclusion.jpg",
        "normalmap": "textures/Floor/Terrazzo_Tiles_001/Terrazzo_Tiles_001_normal.jpg",
        "colormap": "textures/Floor/Terrazzo_Tiles_001/Terrazzo_Tiles_001_basecolor.jpg"
    }
}